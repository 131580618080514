<template>
  <div class="home">
    <div class="readImg">
      <div class="readTitle">书目推荐</div>
      <div class="readEnglish">Recommended Books</div>
    </div>
    <div class="headTop"><HeadTop /></div>
    <div class="content">
      <div class="contLeft">
        <div class="search">
          <div class="searchLeft">
            <span class="spanText">排序</span>
            <span :class="selectCurrent==index?'spanActive':'spanText1'" v-for="(item, index) in selectSearch" :key="item.key" @click="searchSelect(index,item)">{{item.name}}</span>
          </div>
          <div class="searchRight">
            <a-input-search placeholder="请输入书本名称" style="width: 300px" @search="onSearch" />
          </div>
        </div>
        <ul class="bookUl">
          <li class="bookLi" v-for="(item, index) in list" :key="index">
            <div class="bookName">
              <img :src="resBasePath+'/'+item.coverPath" alt="">
            </div>
            <ul class="bookArea" v-if="item.teaPlanList!=null&&item.teaPlanList.length>0">
              <li class="bookDetail" v-for="(i, index) in item.teaPlanList" :key="index" @click="goDetail(i)">
                <span class="name">{{i.title}}</span>
                <span class="date">{{format.format(i.createDate)}}</span>
              </li>
            </ul>
          </li>

        </ul>
        <div style="text-align:center;margin:15px 0px;">
          <a-config-provider>
            <a-pagination 
              show-quick-jumper
              :default-current="1"
              :page-size="pageSize"
              @change="handleListChange"
              show-less-items
              :total=total />
            </a-config-provider>
          </div>
      </div>
      <div class="contRight">
        <div class="title">
          <img width="20px" height="20px" src="../../assets/img/53.png" alt="">
          <span class="good_product">精品</span>
        </div>
        <ul class="pro_list">
          <li class="listDetail ell" @click="goDetail(item)" v-for="(item, index) in recList" :key="index">
            {{item.title}}
          </li>
        </ul>
        <div class="title">
          <img width="20px" height="20px" src="../../assets/img/54.png" alt="">
          <span class="good_product">论坛</span>
        </div>
        <ul class="lun_ul">
          <li class="lun_li" v-for="(item, index) in forumList" :key="index">
            <div class="title ell">{{item.title}}</div>
            <div class="zhuban">主办方：{{item.stemFrom}}</div>
            <div class="date">
              时    间：{{format.format(item.startDate)}}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import HeadTop from '@/components/HeadTop.vue'
import format from '@/utils/format.js'
import axios from 'axios'
export default {
  name: 'programRecommend',
  data(){
    return {
      format,
      resBasePath: this.$resBasePath,
      id: '1',// 书籍ID
      pageSize: 5,
      pageNum: 1,
      total: 0,
      sort: '',
      searchTitle: '',//搜索
      selectCurrent:0,
      selectSearch: [
        {name: '默认', key: '1'},
        {name: '按时间', key: '2'}
      ],
      list:[],
      recList:[],// 推荐
      forumList:[]
    }
  },
  created () {
    // this.$emit('footer', false);
  },
  mounted (){
    this.getList()
    this.recommandList()
  },
  methods: {
    getList (){ //获取书屋推荐详情	
      const param = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        sort: this.sort,
        title: this.searchTitle
      }
        let _this =this
        axios({
          url: _this.$baseUrl + 'official/bookStore/getClassicsReadPage',
          method: 'POST',
          data: param
        }).then(res => {
          if(res && res.data &&res.data.resultCode===0){   
            _this.list = res.data.resultData.list
            _this.total = res.data.resultData.total
          }
        })
    },
    recommandList (){ //official/bookStore/getExcellentAndForumList
        let _this = this
        axios({
          url: _this.$baseUrl + 'official/bookStore/getExcellentAndForumList',
          method: 'POST',
          data: {}
        }).then(res => {
          if(res && res.data &&res.data.resultCode===0){   
            _this.recList = res.data.resultData.excellentList
            _this.forumList = res.data.resultData.forumList
          }
        })
    },
    searchSelect (index, item) {
      this.selectCurrent = index
      this.sort = item.key
      this.getList()
    },
    onSearch(e){
      this.searchTitle = e
      this.getList()
    },
    handleListChange (current, pageSize) {
      this.pageNum = current
      this.pageSize = pageSize
      this.getList()
    },
    goProfect(){
      this.$router.push({name: 'programRecommend'})
    },
    goDetail(item){
      this.$router.push({name: 'bookDetail'})
      sessionStorage.setItem('bookRecommend',JSON.stringify(item))
    }
  },
  components: {
    HeadTop
  }
}
</script>
<style scoped lang="less">
ul,li{
  list-style: none;
  margin: 0;
  padding: 0;
}
  .home{
    background: #f6f6f6;
    .readImg{
      width:100%;
      height:260px;
      background: url('../../assets/img/34.png');
      background-size: 100% 100%;
      position: relative;
      .readTitle{
        text-align: center;
        color: #323232;
        line-height: 55px;
        letter-spacing: 4px;
        font-size: 36px;
        padding-top: 80px;
      }
      .readEnglish{
        color: #3D3D3D;
        line-height: 27px;
        letter-spacing: 2px;
        font-size: 16px;
        text-align: center;
      }
    }
    .headTop{
      width: 1140px;
      margin: 0 auto;
    }
    .content{
      width: 1140px;
      // height: 400px;
      background: #f6f6f6;
      margin: 0 auto;
      .contLeft{
        padding: 30px 50px;
        background: #fff;
        margin-bottom: 30px;
        width: 820px;
        float: left;
        .search{
          height: 40px;
          margin-bottom: 10px;
          display: flex;
          .searchLeft{
            display: flex;
            cursor: pointer;
            .spanText{
              display: inline-block;
              width: 80px;
              height: 32px;
              line-height:32px;
              color: #000;
              font-size: 14px;
              text-align: center;
              margin-left: 10px;
            }
          }
          .searchRight{
            margin-left: auto;
          }
        }
        .bookUl{
          .bookLi{
            margin-top: 15px;
            width: 100%;
            border-bottom: 1px solid #EEE9DE;
            display: flex;
            cursor: pointer;
            .bookName{
              flex: 1;
              text-align: center;
              img{
                width: 120px;
                height: 140px;
              }
            }
            .bookArea{
              flex: 4;
              padding: 0px 0px 0px 4px;
              width: 100%;
              .bookDetail{
                border-bottom: 1px solid #EEE9DE;
                height: 52px;
                line-height: 52px;
                .name{
                  display: inline-block;
                  width:400px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-weight: 400;
                  color: #000000;
                  font-size: 16px;
                }
                .date{
                  display: inline-block;
                  float: right;
                  color: #7F7F7F;
                  font-size: 14px;
                }
                &:last-child{
                  border-bottom:none;
                }
              }
            }
          }
        }
      }
      //right
      .contRight{
        background: #fff;
        padding: 20px;
        width: 300px;
        float: left;
        margin-left: 20px;
        .title{
          height: 40px;
          line-height: 40px;
          margin-top: 10px;
          .good_product{
            font-size: 16px;
            color: #DB5647;
            font-weight: 500;
            margin-left: 10px;
          }
        }
        .pro_list{
          margin-top: 10px;
          .listDetail{
            border-bottom: 1px solid #EEE9DE;
            color: #000000;
            line-height: 56px;
            height: 56px;
            width: 250px;
            cursor: pointer;
          }
        }
        .lun_ul{
          margin-top: 20px;
          .lun_li{
            border-bottom: 1px solid #EEE9DE;;
            .title{
              color: #000000;
              line-height: 30px;
              height: 30px;
              font-size: 14px;
              width: 250px;
            }
            .zhuban{
              color: #7F7F7F;
              height: 30px;
              line-height: 30px;
              font-size: 12px;
            }
            .date{
              color: #7F7F7F;
              height: 30px;
              line-height: 30px;
              font-size: 12px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
  .spanActive{
    width: 80px;
    height: 32px;
    line-height: 32px;
    background: #FBEEEC;
    border-radius: 4px;
    color: #DB5647;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    margin-left: 10px;
  }
  .spanText1{
    background: #f6f6f6;
    width: 80px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 10px;
  }
  //gonggomg
  .ell{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>